<template>
  <div class="min-h-screen">
    <section class="layout bg-blue-darker pb-44 pt-10vh">
      <div class="container pt-20 pb-10">
        <h1 class="text-4xl font-medium leading-normal text-white text-center">Lorem ipsum dolor</h1>
      </div>
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <skewed-separator class="text-white" />
    </section>
    <div class="container overlap">
      <div class="flex justify-center">
        <card class="w-full md:w-1/2 p-4">
          <notice v-if="errors != false" type="error" :messages="errors" />
          <form v-if="show">
            <input-container icon="letter">
              <input type="email" v-model="form.email" placeholder="Email" required />
            </input-container>
            <input-container icon="key">
              <input type="password" v-model="form.password" placeholder="Password" required />
            </input-container>
            <btn class="btn-orange w-full" @click.native="submit">Login</btn>
          </form>
          <div class="flex justify-between mt-4">
            <router-link class="text-sm text-grey-darker no-underline" :to="{name: 'register'}">Don't have an account?</router-link>
            <router-link class="text-sm text-grey-darker no-underline" :to="{name: 'forgot-password'}">Forgotten your password?</router-link>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../partials/Card';
import SkewedSeparator from '../partials/SkewedSeparator';
import Btn from '../partials/Btn';
import Icon from '../partials/Icon';
import CircleBg from '../partials/CircleBg';
import InputContainer from '../partials/InputContainer';
import Notice from '../partials/Notice';
export default {
  components: { Notice, InputContainer, CircleBg, Icon, Btn, SkewedSeparator, Card },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      show: true,
      errors: false,
    };
  },
  methods: {
    submit() {
      this.errors = false;

      this.$store.dispatch(window.store.POST_USER_LOGIN, this.form)
        .then(() => {
          this.$router.push({ name: 'dashboard' });
        })
        .catch((errors) => {
          this.errors = errors;
        });
    },
    // reset() {
    //   // Reset our form values
    //   this.form.email = '';
    //   this.form.password = '';
    //   // Trick to reset/clear native browser form validation state
    //   this.show = false;
    //   this.$nextTick(() => {
    //     this.show = true;
    //   });
    // },
  },
};
</script>
